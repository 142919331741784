.step-item {
  @apply -mb-[13px];
}

.step {
  @apply w-6 h-6 flex items-center justify-center z-10 relative bg-gray-70-inncash rounded-full font-semibold text-white;
}
.active .step {
  @apply bg-green-500-attention;
}
.complete .step {
  @apply bg-green-500-attention;
}

.complete p {
  @apply text-white;
}
